@import '_animation_transitions';

.app {
	padding: em(23) em(20);
	margin: em(20);
	background: $red;
	border-radius: 20px;
	min-height: calc(100vh - #{em(20 * 2)});
	display:flex;
	flex-direction: column;
	align-content: center;
	& > div {
		flex-grow: 1;
		display: flex;
		justify-content: center;
	}
}

.header {
	flex-grow: 0;
	flex-shrink: 0;
	margin-bottom: em(30);
}

main {
	display: absolute;
	flex-grow: 1;
	max-width: #{$wrap}px;
	display: inherit;
	flex-direction: column;
	justify-content: space-evenly;
	text-align: center;
}

.result__note {
	margin-bottom: em(20);
}