// Breakpoints.
$responsive: true;
$breakpoints: ( 'full': (min-width: 1200px), 'wide': (min-width: 1023px), 'viewport': (min-width: 979px), 'tall': (min-width: 767px), 'small': (min-width: 550px), 'mini': (min-width: 420px));
// Mixin para responsive uso @include respond-to(full) {....}
@mixin respond-to($breakpoint) {
    // If the key exists in the map
    @if $responsive {
        @if map-has-key($breakpoints, $breakpoint) {
            // Prints a media query based on the value
            @media #{inspect(map-get($breakpoints, $breakpoint))} {
                @content;
            }
        }
        // If the key doesn't exist in the map
        @else {
            @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "+"Please make sure it is defined in `$breakpoints` map.";
        }
    }
}