.email-modal{
	margin: 0 auto;
	background-color: $another-dark-red;
	position: absolute;
	padding: 25px;
	left: 50%;
	top: 50%;
	width: calc(100% - 40px);
	transform: translate(-50%, -50%); 
  	font-family: 'Cocogoose';
  	box-shadow: 20px 20px 0 rgba(black, .45);
  	animation: fade-slide-up 500ms ease-out;
  	& .close{
			position: absolute;
			background: transparent;
  		right:14px;
  		top:14px;
  		font-family: 'Cocogoose';
  		font-size: em(35);
		color: rgba(black, .4);
  	}
	& .text{
		color: $white;
		margin-bottom: 20px;
	}
	& .separacion{
		width: 30px;
		height: 10px;
		background-color: rgba(black, .4);
		margin: 0 auto;
		margin-bottom: 20px;
	}
	& label{
		font-size: em(11);
		color: $white;
		margin: 0 auto;
		margin-top: 12px;
		margin-bottom: 12px;
		display: block;
		& a{
			display: inline-block;
			text-decoration: underline;
		}
	}
	& input[type="text"]{
		width: 85%;
		max-width: 450px;
		border: 4px solid $white;
		background-color: $another-dark-red;
		border-radius: 12px;
		color: $white;
		font-family: Arial;
		font-size: em(14);
		padding: 8px 12px;
	}
	& .boton{
		font-family: 'Cocogoose';
		display: inline-block;
		padding: 10px 5px;
		min-width: 60%;
		margin: 0 auto;
		margin-top: 15px;
		font-size: em(20);
		font-weight: bold;
		text-align: center;
		color: $dark-red;
		background-color: $white;
		border-radius: 8px;
	}
}

@keyframes fade-slide-up {
	0% {
		opacity: 0;
		// transform: translateY(40px);
		transform: translate(-50%, calc(-50% + 40px)); 
	}
	100% {
		opacity: 1;
		transform: translate(-50%, calc(-50% + 0px));
	}
}