.cookies-consent {
  display: flex;
  position: fixed;
  z-index: 10;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  bottom: 0;
  left: 0;
  font-family: Arial, sans-serif;
  color: white;
  background: rgba(black, 0.6);
  font-size: em(14);
  &__contenido {
    margin-right: 10px;
    a {
      color: red;
    }
  }
  padding: em(20, 14);
  &__link {
    display: block;
    border-radius: 5px;
    background: red;
    font-size: em(16);
    padding: em(10, 16) em(20, 16);
    color: white;
  }
}