.thinking {
  padding-top: em(40);
  display: flex;
  flex-direction: column;
  line-height: 1;
  text-transform: uppercase;
  
  &__icon {
    width: flex-width(379, $wrap);
    position: relative;
    padding-bottom: 100%;
    margin-bottom: em(40);
  }
  &__brain, &__wheel {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__wheel {
    animation: step-rotation 600ms steps(12) infinite;
  }
  &__brain {
    // width: flex-width(220, 379);
    background: url(../images/brain.png) center center no-repeat;
    background-size: flex-width(220, 379);
    z-index: 2;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  &__note {
    color: $white;
    span {
      color: $dark-red;
    }
    margin-bottom: em(40, 70);
  }
}

@keyframes step-rotation {
  0% {
    transform: rotate(0deg);
  }
  
  100% {
    transform: rotate(359deg);
  }
}