// Estilos base de los tags
html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    font-family: $main-font;
    color: $black;
    line-height: 1.4;
    min-height: 100%;
    background: $white;
    &.disabled, &.open {
        overflow: hidden;
    }
}

a {
    color: inherit;
    text-decoration: none;
    display: block;
}

input,
select {
    font-family: inherit;
    font-size: inherit;
    border-radius: 0px;
    border-style: solid
}

img,
video {
    max-width: 100%;
    height: auto;
}

button {
    border: 0;
}

h1,
h2,
h3,
p {
    margin-bottom: em(0);
}

canvas {
    display: block;
}

*:focus {
    outline: none;
}

strong, b {
    font-weight: bold;
}

textarea:focus,
input:focus {
    outline: none;
}

.wrapper {
    max-width: #{$ancho-app}px;
    margin: 0 auto;
}

.big {
    display: inline;
}
.small {
    display: none;
}

@include respond-to(tall){
   .big {
       display: none;
   }

   .small {
       display: inline;
   }
}