.modal__legal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(black, 0.95);
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  &-container {
    text-align: center;
    border-radius: 10px;
    border: 1px solid #dea95b;
    color: white;
    padding: 30px;
  }
  &-image {
    margin-bottom: 20px;
  }
  &-bienvenido {
    margin-bottom: 10px;
    font-size: 20px;
  }
  &-mayor {
    margin-bottom: 20px;
    font-size: 30px;
  }
  &-options {
    display: flex;
    justify-content: space-around;
  }
  &-link a, &-link span {
    border-radius: 50%;
    border: 1px solid #dea95b;
    text-decoration: none;
    text-transform: uppercase;
    display: block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    margin-bottom: 20px;
  }
   &-nota {
    margin-bottom: 10px;
    font-size: 14px;
  }
}