// Estilos de los componentes.
@import "components/cookie-consent";
@import "components/legal-age";
@import "components/header";
@import "components/thinking";
@import "components/result";
@import "components/overlay";
@import 'components/errors';
@import 'components/email-modal';
@import 'components/social-menu';

.title {
  @include fluid-type(25px, 40px);
  color: $white; 
  margin-bottom: em(40, 70);
}

.imagen-estrella{
  padding: 20px;
}

.boton-general{
  display: inline-block;
  padding: 10px 5px;
  min-width: 65%;
  margin: 0 auto;
  font-size: em(26);
  font-weight: bold;
  text-align: center;
  font-family: 'Cocogoose';
  color: $white;
  background-color: $dark-red;
  border-radius: 15px;
  position: relative;
  transition: background-color 300ms ease-in;
  &:hover, &:active {
    background: lighten($dark-red, 10);
  }
}

.text{
  margin-top: em(20);
  @include fluid-type(22px, 30px);
  color: $dark-red;
  line-height: em(20);
  & span{
    color:$white;
  }
  &--small {
    margin-bottom: em(20, 22);
    font-size: em(22);
  }
  &--legal {
    font-size: em(18);
    a {
      display: inline-block;
      color: $white;
    }
  }
}