// Transition between modules
.fade-enter {
    opacity: 0.01;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

// Entering element
.slide-left-enter {
  transform: translateX(100%);
  z-index: 2;
} 

.slide-left-enter.slide-left-enter-active {
  transform: translateX(0%);
  transition: transform 1000ms ease-in
}
// Existing element
.slide-left-exit {
  transform: translateX(0%);
  z-index: 1;
}
.slide-left-exit.slide-left-exit-active {
  transform: translateX(-100%);
  transition: transform 1000ms ease-in;
}

.slide-right-enter {
  transform: translateX(-100%);
  z-index: 2;
} 

.slide-right-enter.slide-right-enter-active {
  transform: translateX(0%);
  transition: transform 1000ms ease-in
}
// Existing element
.slide-right-exit {
  transform: translateX(0%);
  z-index: 1;
}
.slide-right-exit.slide-right-exit-active {
  transform: translateX(100%);
  transition: transform 1000ms ease-in;
}