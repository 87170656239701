.overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 9999;
	background: transparent url(../images/overlay.png) repeat top left;
	pointer-events: none;
	opacity: 0.8;
}