@import 'utils/variables';
@import 'utils/respond-to';
// Funciones
// Funcion para el calculo en procentage de altura.
@function flex-height( $height, $maxheight: $alto-app) {
    @return calc(#{(($height / ($maxheight)) * 100)}vh);
}

// Convierte valres a em,
$browser-context: 16; // Valor por defecto.
@function em($pixels, $context: $browser-context) {
    @return #{$pixels/$context}em;
}
$baseContext: $ancho-app;
@function flex-width($target, $context: $baseContext) {
    @return percentage($target/$context);
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}


@mixin fluid-type($min-font-size, $max-font-size, $min-vw:320px, $max-vw:800px ) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

@mixin video-responsive($width, $height) {
    position: relative;
    padding-bottom: ($height / $width) * 100%;
    iframe, video, canvas {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%
    }
}

@function ratio-percentage($width: 9, $height: 16) {
  @return ($height / $width) * 100%;
}
